<template>
  <div id="editor">
    <QuillEditor
        theme="snow"
        toolbar="full"
        :modules="modules"
    />
    <button @click="sendEmail">Send Email</button>
  </div>
</template>


<script>
import axios from "axios";
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from 'quill-image-uploader';


export default {
  name: 'EditorPage',
  components: {
    QuillEditor,
  },
  data () {
    return {
      modules: [{
        name: 'imageUploader',
        module: ImageUploader,
        options: {
          upload: file => {
            return this.upload(file);
          },
        }
      }],
    }
  },
  methods: {
    upload(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        axios.post('/upload-image', formData)
            .then(res => {
              console.log(res)
              resolve(res.data.url);
            })
            .catch(err => {
              reject("Upload failed");
              console.error("Error:", err)
            })
      })
    },
    sendEmail() {
      const content = this.$refs.editor.quill.root.innerHTML;
      axios.post('/send-email', { content })
          .then(response => {
            console.log('Email sent successfully:', response);
          })
          .catch(error => {
            console.error('Failed to send email:', error);
          });
    }
  }
}
</script>
