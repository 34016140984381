import '@/style.css';
import App from './App.vue'
import { createApp } from 'vue'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';


const setupApp = async () => {
    const app = createApp(App);
    app.component(QuillEditor);
    app.mount('#app');
}
setupApp().then(r => {return r});
