<template>
  <div id="app">
    <EditorPage />
  </div>
</template>

<script>

import EditorPage from "@/pages/EditorPage.vue";

export default {
  name: 'App',
  components: {
    EditorPage
  }
}
</script>

